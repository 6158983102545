.eigentumer-v1 {
	margin-top: var(--page-margin-top);

	.inner-banner {
		//background-image: url(RESOURCE/img/cover-eigentumer.jpg);
		background-image: url(RESOURCE/img/eigentumer-banner.jpg);
		background-position-y: 60%;

	}

	ul {
		list-style: initial;
		font-family: var(--font-family-secondary);

		li {
			margin: 4px 0;
		}
	}
}