.index-zig-zag-v1 {
	background: #f4f4f4;

	.first-box {
		.row {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0;

			img {
				max-width: 100%;
			}

			.content {
				padding-right: 0;
				max-width: 550px;
				width: 100%;
				margin: 0 auto;
				padding: 50px 30px;


			}
		}
	}

	.sec-box {
		.row {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0;

			@media (max-width: 991px) {
				flex-direction: column-reverse;
			}

			img {
				max-width: 100%;
			}

			.content {
				padding-right: 0;
				max-width: 550px;
				width: 100%;
				margin: 0 auto;
				padding: 50px 30px;
			}
		}
	}
}