@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 300;
	src: local(''),
		url('RESOURCE/fonts/poppins-v15-latin-300.woff2') format('woff2'),
		url('RESOURCE/fonts/poppins-v15-latin-300.woff') format('woff');
	font-display: swap;
}

/* poppins-regular - latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/poppins-v15-latin-regular.woff2') format('woff2'),
		url('RESOURCE/fonts/poppins-v15-latin-regular.woff') format('woff');
	font-display: swap;
}

/* poppins-500 - latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	src: local(''),
		url('RESOURCE/fonts/poppins-v15-latin-500.woff2') format('woff2'),
		url('RESOURCE/fonts/poppins-v15-latin-500.woff') format('woff');
	font-display: swap;
}

/* poppins-600 - latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	src: local(''),
		url('RESOURCE/fonts/poppins-v15-latin-600.woff2') format('woff2'),
		url('RESOURCE/fonts/poppins-v15-latin-600.woff') format('woff');
	font-display: swap;
}

/* poppins-700 - latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	src: local(''),
		url('RESOURCE/fonts/poppins-v15-latin-700.woff2') format('woff2'),
		url('RESOURCE/fonts/poppins-v15-latin-700.woff') format('woff');
	font-display: swap;
}

@font-face {
	font-family: 'Ubuntu';
	src: url('Ubuntu-Bold.eot');
	src: url('RESOURCE/fonts/Ubuntu-Bold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Ubuntu-Bold.woff2') format('woff2'),
		url('RESOURCE/fonts/Ubuntu-Bold.woff') format('woff'),
		url('RESOURCE/fonts/Ubuntu-Bold.ttf') format('truetype'),
		url('RESOURCE/fonts/Ubuntu-Bold.svg#Ubuntu-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Ubuntu';
	src: url('RESOURCE/fonts/Ubuntu-Medium.eot');
	src: url('RESOURCE/fonts/Ubuntu-Medium.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Ubuntu-Medium.woff2') format('woff2'),
		url('RESOURCE/fonts/Ubuntu-Medium.woff') format('woff'),
		url('RESOURCE/fonts/Ubuntu-Medium.ttf') format('truetype'),
		url('RESOURCE/fonts/Ubuntu-Medium.svg#Ubuntu-Medium') format('svg');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Ubuntu';
	src: url('RESOURCE/fonts/Ubuntu-Regular.eot');
	src: url('RESOURCE/fonts/Ubuntu-Regular.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Ubuntu-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/Ubuntu-Regular.woff') format('woff'),
		url('RESOURCE/fonts/Ubuntu-Regular.ttf') format('truetype'),
		url('RESOURCE/fonts/Ubuntu-Regular.svg#Ubuntu-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Ubuntu';
	src: url('RESOURCE/fonts/Ubuntu-Light.eot');
	src: url('RESOURCE/fonts/Ubuntu-Light.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Ubuntu-Light.woff2') format('woff2'),
		url('RESOURCE/fonts/Ubuntu-Light.woff') format('woff'),
		url('RESOURCE/fonts/Ubuntu-Light.ttf') format('truetype'),
		url('RESOURCE/fonts/Ubuntu-Light.svg#Ubuntu-Light') format('svg');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Bitter';
	src: url('RESOURCE/fonts/Bitter-Thin.eot');
	src: url('RESOURCE/fonts/Bitter-Thin.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Bitter-Thin.woff2') format('woff2'),
		url('RESOURCE/fonts/Bitter-Thin.woff') format('woff'),
		url('RESOURCE/fonts/Bitter-Thin.ttf') format('truetype'),
		url('RESOURCE/fonts/Bitter-Thin.svg#Bitter-Thin') format('svg');
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Bitter';
	src: url('RESOURCE/fonts/Bitter-ExtraLight.eot');
	src: url('RESOURCE/fonts/Bitter-ExtraLight.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Bitter-ExtraLight.woff2') format('woff2'),
		url('RESOURCE/fonts/Bitter-ExtraLight.woff') format('woff'),
		url('RESOURCE/fonts/Bitter-ExtraLight.ttf') format('truetype'),
		url('RESOURCE/fonts/Bitter-ExtraLight.svg#Bitter-ExtraLight') format('svg');
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Bitter';
	src: url('RESOURCE/fonts/Bitter-Light.eot');
	src: url('RESOURCE/fonts/Bitter-Light.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Bitter-Light.woff2') format('woff2'),
		url('RESOURCE/fonts/Bitter-Light.woff') format('woff'),
		url('RESOURCE/fonts/Bitter-Light.ttf') format('truetype'),
		url('RESOURCE/fonts/Bitter-Light.svg#Bitter-Light') format('svg');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Bitter';
	src: url('RESOURCE/fonts/Bitter-Regular.eot');
	src: url('RESOURCE/fonts/Bitter-Regular.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Bitter-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/Bitter-Regular.woff') format('woff'),
		url('RESOURCE/fonts/Bitter-Regular.ttf') format('truetype'),
		url('RESOURCE/fonts/Bitter-Regular.svg#Bitter-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Bitter';
	src: url('RESOURCE/fonts/Bitter-Medium.eot');
	src: url('RESOURCE/fonts/Bitter-Medium.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Bitter-Medium.woff2') format('woff2'),
		url('RESOURCE/fonts/Bitter-Medium.woff') format('woff'),
		url('RESOURCE/fonts/Bitter-Medium.ttf') format('truetype'),
		url('RESOURCE/fonts/Bitter-Medium.svg#Bitter-Medium') format('svg');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Bitter';
	src: url('RESOURCE/fonts/Bitter-SemiBold.eot');
	src: url('RESOURCE/fonts/Bitter-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Bitter-SemiBold.woff2') format('woff2'),
		url('RESOURCE/fonts/Bitter-SemiBold.woff') format('woff'),
		url('RESOURCE/fonts/Bitter-SemiBold.ttf') format('truetype'),
		url('RESOURCE/fonts/Bitter-SemiBold.svg#Bitter-SemiBold') format('svg');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Bitter';
	src: url('RESOURCE/fonts/Bitter-Bold.eot');
	src: url('RESOURCE/fonts/Bitter-Bold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Bitter-Bold.woff2') format('woff2'),
		url('RESOURCE/fonts/Bitter-Bold.woff') format('woff'),
		url('RESOURCE/fonts/Bitter-Bold.ttf') format('truetype'),
		url('RESOURCE/fonts/Bitter-Bold.svg#Bitter-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Bitter';
	src: url('RESOURCE/fonts/Bitter-ExtraBold.eot');
	src: url('RESOURCE/fonts/Bitter-ExtraBold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Bitter-ExtraBold.woff2') format('woff2'),
		url('RESOURCE/fonts/Bitter-ExtraBold.woff') format('woff'),
		url('RESOURCE/fonts/Bitter-ExtraBold.ttf') format('truetype'),
		url('RESOURCE/fonts/Bitter-ExtraBold.svg#Bitter-ExtraBold') format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Bitter';
	src: url('RESOURCE/fonts/Bitter-Black.eot');
	src: url('RESOURCE/fonts/Bitter-Black.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Bitter-Black.woff2') format('woff2'),
		url('RESOURCE/fonts/Bitter-Black.woff') format('woff'),
		url('RESOURCE/fonts/Bitter-Black.ttf') format('truetype'),
		url('RESOURCE/fonts/Bitter-Black.svg#Bitter-Black') format('svg');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}