.kreativ-raum-v1 {
	margin-top: var(--page-margin-top);

	.inner-banner {
		background-image: url(RESOURCE/img/strand-himmel-1.jpg);
		background-position: center center;
	}

	.kreativ-raum-boxes {
		.box-part {
			margin-bottom: 30px;


			img {
				max-width: 100%;
			}
		}


	}
}