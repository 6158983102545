.buchbare-leistungen-v1 {
	margin-top: var(--page-margin-top);

	.inner-banner {
		background-image: url(RESOURCE/img/strand-himmel-1.jpg);
		background-position: center center;
	}




	.Buchbare-boxes {
		margin: 50px 0 0 0;


		.col-lg-4 {
			@media all and (min-width: 992px) and (max-width: 1199px) {
				max-width: 50%;
				width: 100%;
				flex: unset;
			}
		}

		.Buchbare-box {
			display: flex;
			align-items: center;
			border: 1px solid #ccc;
			padding: 0;
			min-height: 130px;
			margin: 0 0 30px 0;

		}

		.img {
			width: 120px;
			min-width: 120px;
			padding: 30px;
			background: #f4f4f4;
			height: 100%;
			min-height: 130px;
			display: flex;
			align-items: center;
			justify-content: center;

			@media (max-width: 480px) {
				padding: 15px;
				width: 90px;
				min-width: 90px;
			}

			img {
				width: 100%;
			}
		}

		.content {
			padding: 15px;
			background: #fff;

			h4 {
				text-align: left;
				color: #333;
				font-size: 20px;
				margin: 0;

				@media (max-width: 480px) {
					font-size: 18px;
				}

				@media all and (min-width: 768px) and (max-width: 1199px) {
					font-size: 18px;
				}
			}

			p {
				margin: 10px 0 0 0;

				@media (max-width: 480px) {
					font-size: 15px;
				}

				@media all and (min-width: 768px) and (max-width: 1199px) {
					font-size: 15px;
				}
			}
		}


	}
}