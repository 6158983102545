.immer-aktuell-v1 {
	.facebook {
		margin-bottom: 30px;

		a {
			color: #fff;
			font-size: 50px;
			background: -webkit-linear-gradient(60deg, #3b5998, #2b4170);
			display: flex;
			align-items: center;
			justify-content: center;
			height: 350px;
			text-decoration: none !important;
			-webkit-transition: background 0.4s linear;
			-moz-transition: background 0.4s linear;
			-o-transition: background 0.4s linear;
			transition: background 0.4s linear;

			@media (max-width: 575px) {
				height: 300px;
			}

			@media (max-width: 767px) {
				height: 180px;
				font-size: 35px;
			}

			@media all and (min-width: 768px) and (max-width: 991px) {
				height: 250px;
			}

			&:hover {
				background: -webkit-linear-gradient(135deg, #2b4170, #3b5998);
			}
		}
	}

	.instagram {
		margin-bottom: 30px;

		a {
			color: #fff;
			font-size: 50px;
			background: -webkit-linear-gradient(60deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d);
			display: flex;
			align-items: center;
			justify-content: center;
			height: 350px;
			text-decoration: none !important;
			-webkit-transition: background 0.4s linear;
			-moz-transition: background 0.4s linear;
			-o-transition: background 0.4s linear;
			transition: background 0.4s linear;

			@media (max-width: 575px) {
				height: 300px;
			}

			@media (max-width: 767px) {
				height: 180px;
				font-size: 35px;
			}

			@media all and (min-width: 768px) and (max-width: 991px) {
				height: 250px;
			}

			&:hover {
				background: -webkit-linear-gradient(135deg, #fd1d1d, #e1306c, #c13584, #833ab4, #5851db, #405de6);
			}
		}
	}
}