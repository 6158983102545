.search-page-layout-v1 {
	background-color: var(--color-grey-light);
	margin-top: var(--page-margin-top);

	@media (max-width: 767px) {
		margin-top: 115px;
	}

	@media all and (min-width: 768px) and (max-width: 991px) {
		margin-top: 112px;
	}
}