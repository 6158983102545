.footer-v1 {

	.main-footer {

		background-color: var(--footer-bg-color);
		color: var(--footer-font-color);
		border-top: 1px solid var(--footer-border-top-color);

		padding-left: 5vw;
		padding-right: 5vw;

		@media (max-width: 767px) {
			padding-bottom: 20px !important;
		}

		p {
			font-family: var(--font-family-main);
		}

		.col-lg-3 {
			@media all and (min-width: 993px) and (max-width: 1199px) {
				max-width: 33.33%;
				flex: unset;
				margin-bottom: 30px !important;
			}
		}

		.col-lg-2 {
			@media all and (min-width: 993px) and (max-width: 1199px) {
				max-width: 33.33%;
				flex: unset;
				margin-bottom: 30px !important;
			}
		}

		.headline {
			font-size: 16px;
			color: var(--footer-font-color);
			font-weight: bold;
			text-transform: uppercase;
		}

		.list-icon {
			li {
				position: relative;
				padding: 0 0 0 38px;
				margin: 0 0 10px 0;
				line-height: 28px;

				i {
					background-color: var(--color-primary);
					width: 28px;
					height: 28px;
					display: flex;
					align-items: center;
					justify-content: center;
					color: rgb(255, 255, 255);
					position: absolute;
					padding: 5px;
					border-radius: 100px;
					left: 0;
					font-size: 12px;
				}
			}
		}


		.logo {
			width: 100px;
			height: auto;
			margin-top: -10px;
			padding-bottom: 10px;
		}

		.openings {
			font-style: italic;

			div {
				padding-top: 10px;
			}
		}

		a {
			color: var(--footer-font-color);
		}

		.social-icons {

			li {

				a {
					color: var(--footer-font-color);
				}

				.fa {
					font-size: 20px;
					color: var(--footer-font-color);

				}
			}

		}




		#button-addon1 {
			color: #ffc371;
		}

		.btn-link {
			background-color: var(--footer-btn-bg-color);

			.fa {
				color: var(--footer-icon-color);
			}
		}

		i {
			color: #ffc371;
		}

		.form-control::placeholder {
			font-size: 0.95rem;
			color: #aaa;
			font-style: italic;
		}

		.form-control.shadow-0:focus {
			box-shadow: none;
		}
	}

	.copyright {
		background-color: var(--footer-copyright-bg-color);
		color: var(--footer-copyright-font-color);
	}

}